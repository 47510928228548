@use '@angular/material' as mat;
@import "font-awesome/css/font-awesome.min.css";

$primary: (
 Main:#187c72,
 Light:#52aca0,
 Dark: #004f47,
 contrast: (
  Main: #fff,
  Light: #000,
  Dark: #fff  
 ));
$accent: (
 Main: #97dbad,
 Light: #c8ffff,
 Dark: #65a9a7,
 contrast: (
   Main: #000,
   Light: #000,
   Dark: #fff
  ));

$custom-theme-primary: mat.define-palette($primary, Main, Light, Dark);
$custom-theme-accent: mat.define-palette($accent, Main, Light, Dark);

$custom-theme: mat.define-light-theme((
    color: (
        primary: $custom-theme-primary, 
        accent: $custom-theme-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
));

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "@angular/material/prebuilt-themes/pink-bluegrey.css";
@import "@angular/material/prebuilt-themes/purple-green.css";

@include mat.core();

/*
// Define a dark theme
$dark-theme: mat.define-dark-theme((
 color: (
   primary: mat.define-palette(mat.$pink-palette),
   accent: mat.define-palette(mat.$blue-grey-palette),
 ),
  // Only include `typography` and `density` in the default dark theme.
  typography: mat.define-typography-config(),
  density: 0,
));
*/

// Define a light theme
$light-theme: mat.define-light-theme((
 color: (
   primary: mat.define-palette(mat.$green-palette),
   accent: mat.define-palette(mat.$blue-palette),
 ),
 typography: mat.define-typography-config()
));

$applied-theme: $custom-theme;

// Apply the dark theme by default
@include mat.core-theme($applied-theme);
@include mat.button-theme($applied-theme);
@include mat.toolbar-theme($applied-theme);
@include mat.input-theme($applied-theme);
@include mat.form-field-theme($applied-theme);
@include mat.core-color($applied-theme);
@include mat.button-color($applied-theme);
@include mat.datepicker-theme($applied-theme);
@include mat.dialog-theme($applied-theme);
@include mat.progress-spinner-theme($applied-theme);
@include mat.snack-bar-theme($applied-theme);
@include mat.chips-theme($applied-theme);
@include mat.tooltip-theme($applied-theme);
@include mat.expansion-theme($applied-theme);

/*
// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: dark) {
 // Use the `-color` mixins to only apply color styles without reapplying the same
 // typography and density styles.
 @include mat.core-color($light-theme);
 @include mat.button-color($light-theme);
}
*/


body, html {
    background: #EEE;
    margin:0;
    padding: 0;
}

.mat-mdc-card {
    margin: 10px 5px 0 5px;
    background: #FFF !important;
}


.mat-mdc-raised-button {
    margin: 5px !important;
    margin-left: 0 !important;
}

.full-width {
    width:100%;
}

.tiny-width {
    width:50px;
}

.small-width {
    width:80px;
}

.hidden {
    display: none;
}

.loader {    
    background: #000;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    width: 100vw;
    opacity: 0.5;
    
    .mat-mdc-progress-spinner {
        margin: 40% auto;
    }
}

.mat-mdc-card.signin {

    max-width: 400px;
    background: #197d72 !important;
    margin: 0 0 0 0;

    .mat-mdc-card-header {
        color: #FFF;
        margin-bottom: 10px;
    }

    .auth-option {
        min-height: 100px;

        .logo {
            width: 150px;
        }
    }
}

main,
.top {
    width: 100vw;
    max-width: 1000px;
}

.logo {
    margin: 20px 0;  
    text-align: center;

    img {
        margin-left: 15px;
        margin-bottom:10px;
    }

    a, a:visited {
        color: #187c72;
        text-decoration: none;
        font-size: 14px;
        border-bottom: 1px solid #187c72;
        padding-bottom: 3px;
    }
}

main {
    margin: 0 auto;
}

/*
.fixed-header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw !important;
}
*/

.auth-option {
    width:100%;
    img {
        max-width: 225px;
    }
}

.cornered {
    float: right;
    margin-top: -8px !important;
}

@media(min-width:961px)
{
    main {
        padding: 10px;    
    }  
}


.fill-remaining-space {
    flex: 1 1 auto;
}

.top .title {
    font-size: 120%;
    position: absolute;
    margin-top:5px;
}

.top .mat-mdc-raised-button {
    float: right;
}